import React, { useState } from "react";
import { useAccessToken } from "./hooks/useAuthentication";
import Header from "./components/Header";
import GenerateView from "./components/Generate";
import LoginRequired from "./components/LoginRequired";
import ExcelUploader from "./components/ExcelUploader";
import * as Sentry from "@sentry/react";

import "./index.css";

Sentry.init({
  // dsn: "https://238d6cdf2970e0cb14c17243eb728cbb@o1168654.ingest.us.sentry.io/4508252116484096",
  // integrations: [Sentry.browserTracingIntegration()],
  // tracesSampleRate: 1.0,
});

const App: React.FC = () => {
  const { accounts } = useAccessToken();
  const [view, setView] = useState<string>("upload");

  return (
    <div className="App">
      <Header setView={setView} view={view} />
      {view === "upload" && (
        <div>{accounts[0] ? <ExcelUploader /> : <LoginRequired />}</div>
      )}
      {view === "generate" && <GenerateView />}
    </div>
  );
};

export default App;
