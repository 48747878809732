import { useState, useEffect } from "react";
import { backendUrl } from "../constants";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const GenerateView = () => {
  // const [selectionView, setSelectionView] = useState<boolean>(true);
  const [data, setData] = useState<{ code: string; name: string }[] | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/funds`, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          setData(result); // Store the result in state
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the fetch function
  }, []);

  const generatePdf = async (code: string) => {
    try {
      const response = await fetch(`${backendUrl}/generatePdf/${code}`, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.blob();
        // Assuming you want to download the PDF
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${code}.pdf`;
        link.click();
      } else {
        console.error("Failed to generate PDF:", response);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div>
      <h1>Generate View Content</h1>
      {data ? (
        <ul>
          {data.map(({ code, name }) => (
            <div key={code}>
              <li>
                {/* <span className={`${name ? "" : "bg-red-100"} p-2 rounded`}> */}
                {name ? (
                  <>
                    {name}
                    <button
                      onClick={() => generatePdf(code)}
                      className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
                    >
                      Generate {code}
                    </button>
                  </>
                ) : (
                  <span className="flex items-center text-red-500">
                    <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" />
                    {`${code} (no name)`}
                  </span>
                )}
                {/* </span> */}
              </li>
            </div>
          ))}
        </ul>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GenerateView;
