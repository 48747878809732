import React from "react";

import { LockClosedIcon } from "@heroicons/react/24/solid";

import { useAccessToken } from "../hooks/useAuthentication";

const LoginRequired: React.FC = () => {
  const { instance, accounts } = useAccessToken();
  console.log(accounts);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full text-center">
        <LockClosedIcon className="h-16 w-16 text-blue-500 mx-auto mb-4" />
        <h1 className="text-2xl font-bold text-gray-800 mb-2">
          Access Restricted
        </h1>
        <p className="text-gray-600 mb-6">
          You need to log in to view this page. Please sign in to continue.
        </p>
        <button
          onClick={() => instance?.loginRedirect()}
          className="w-full px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition"
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default LoginRequired;
