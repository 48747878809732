import React from "react";
import { useAccessToken } from "../hooks/useAuthentication";

interface HeaderProps {
  setView: (view: string) => void;
  view: string;
}

const Header: React.FC<HeaderProps> = ({ setView, view }) => {
  const { instance, accounts } = useAccessToken();

  return (
    <header className="bg-gray-800 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo/Title */}
        <h1 className="text-2xl font-bold tracking-wide">
          Excellerate Your Fund Facts
        </h1>

        {/* Authentication Buttons */}
        <div className="space-x-4">
          {accounts?.length > 0 ? (
            <button
              onClick={() => instance?.logoutRedirect()}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md transition"
            >
              Log Out
            </button>
          ) : (
            <button
              onClick={() => instance?.loginRedirect()}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md transition"
            >
              Log In
            </button>
          )}
        </div>
      </div>

      {/* Navigation */}
      <nav className="mt-4 border-t border-gray-700 pt-2">
        <div className="container mx-auto flex space-x-6">
          <button
            onClick={() => setView("upload")}
            className={`px-4 py-2 rounded-md transition ${
              view === "upload"
                ? "bg-blue-500 text-white"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
          >
            Upload
          </button>
          <button
            onClick={() => setView("generate")}
            className={`px-4 py-2 rounded-md transition ${
              view === "generate"
                ? "bg-blue-500 text-white"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
          >
            Generate
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
