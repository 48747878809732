import { useState, useEffect, ChangeEvent } from "react";
import * as XLSX from "xlsx";
import { backendUrl } from "../constants";
import * as Sentry from "@sentry/browser";

const ExcelUploader = () => {
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheets, setSelectedSheets] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "success" | "failure" | null
  >(null);
  const [message, setMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      setFile(uploadedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        setSheets(workbook.SheetNames);
      };
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  // Submit the form with the file and selected sheets
  const handleSubmit = async () => {
    if (!file || selectedSheets.length === 0) {
      setMessage("Please select a file and at least one sheet.");
      setUploadStatus("failure");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sheets", JSON.stringify(selectedSheets));

    try {
      const response = await fetch(`${backendUrl}/upload_excel`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setTaskId(result.task_id); // Assuming backend returns task_id
        setShowModal(true);
        setMessage("Upload in progress...");
        setUploadStatus(null);
      } else {
        setMessage("Upload failed. Please try again.");
        setUploadStatus("failure");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setUploadStatus("failure");
      console.error("Error:", error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!taskId) return;

    const eventSource = new EventSource(`${backendUrl}/progress/${taskId}`);

    eventSource.onmessage = (event) => {
      try {
        const progressData = JSON.parse(event.data); // Parse the incoming JSON data

        // Update progress state with the new data
        setProgress(progressData.percent_complete);

        // Handle the different statuses based on the progress data
        // {"status": "Processing sheet 1/1: Private Lp Fund List Info", "message": "", "percent_complete": 100}

        if (progressData.percent_complete === 100) {
          console.log(progressData);
          if (progressData?.status?.toLowerCase().includes("error")) {
            setUploadStatus("failure");
            setMessage(progressData?.message);
            eventSource.close();
            setShowModal(false);
            var error_message = Sentry.captureMessage(progressData?.message);
            console.log("captured exception");
            console.log(error_message);
          } else {
            setUploadStatus("success");
            setMessage(progressData?.status);
            eventSource.close();
            setShowModal(false);
          }
        } else if (progressData.status === "Error") {
          setUploadStatus("failure");
          setMessage(progressData.message || "An error occurred.");
          eventSource.close();
          setShowModal(false);
        }
      } catch (error) {
        console.error("Error parsing progress data:", error);
        setUploadStatus("failure");
        setMessage("Error occurred while tracking progress.");
        eventSource.close();
        setShowModal(false);
      }
    };

    eventSource.onerror = () => {
      setMessage("An error occurred while tracking progress.");
      setUploadStatus("failure");
      eventSource.close();
      setShowModal(false);
    };

    return () => eventSource.close();
  }, [taskId]);

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <p>File: {fileName}</p>

      {sheets.length > 0 && (
        <div>
          <h3>Select Sheets</h3>
          {sheets.map((sheet, index) => (
            <div key={index}>
              <input
                type="checkbox"
                value={sheet}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setSelectedSheets((prev) =>
                    checked ? [...prev, value] : prev.filter((s) => s !== value)
                  );
                }}
              />
              {sheet}
            </div>
          ))}
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}

      {/* Display Yellow Modal during submission */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            backgroundColor: "#fdfd96",
            padding: "20px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          <h4>Uploading...</h4>
          <p>File: {fileName}</p>
          <p>Sheets: {selectedSheets.join(", ")}</p>
          <p>Progress: {progress}%</p>
        </div>
      )}

      {/* Success Message Modal */}
      {uploadStatus === "success" && (
        <div
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            backgroundColor: "#d4edda",
            padding: "20px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          <h4>Upload Complete</h4>
          <p>{message}</p>
        </div>
      )}

      {/* Failure Message Modal */}
      {uploadStatus === "failure" && (
        <div style={{ color: "red" }}>{message}</div>
      )}
    </div>
  );
};

export default ExcelUploader;
